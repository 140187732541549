import logo from './logo.svg';
import './App.css';
import DynamicImport from 'dynamicimport-test';
import {Onairos} from 'onairos';


function App() {
  function testDynamic(){
    const Dynamic = DynamicImport("x").then((value)=>{
    console.log("Dyanmic : ", value)
    }

    );
  }

  // Prepare the data to be sent
  const requestData = {
    Small: {
      type:'Personality',
      descriptions:'Find out Your Anime Interests',
      reward:""
    },
    Medium:{
      type:'Personality',
      descriptions:'Find out Your Anime Interests',
      reward:""
    },
    Large:{
      type:'Personality',
      descriptions:'Find out Your Anime Interests',
      reward:"$0.30 - Test Money"
    },
  };
  console.log("requestData", requestData)
return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <button onClick={testDynamic}>
          <p>
            Dynamic Import 
          </p>
        </button>

        <Onairos 
              className="text-white font-bold h-8 py-2 px-4 rounded-lg"
              proofMode={false}
              webpageName={"Onairos Anime Store"}
              requestData={requestData}
              // Include any other props that `Onairos` needs
        />
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
